import React, { Fragment, useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import TransactionStatus from './TransactionStatus';
import {
  currencyFormat,
  formatPercent,
  truncateString,
} from '../../../utils/formatters';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddOutlined';
import {
  Avatar,
  Box,
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Paper,
  Divider,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { COFIDIS_STATUS } from '../context/status';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textCenter: {
    textAlign: 'center',
  },
  greyText: {
    color: '#424242',
  },
  greyLightText: {
    color: '#9e9e9e',
    fontWeight: 500,
  },
  text: {
    fontWeight: 600,
    color: '#424242',
  },
  title: {
    margin: '0px auto 15px auto',
    fontWeight: 600,
    color: '#212121',
  },
  icons: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: '#9e9e9e',
  },
  contentCopy: {
    fontSize: 20,
  },
  editId: {
    fontSize: 14,
  },
  detailPaper: {
    backgroundColor: '#f6f6f6',
    padding: '15px 0px 15px 15px',
  },
  formControl: {
    width: '100%',
    marginLeft: 0,
  },
  alert: {
    color: '#ff1744',
  },
}));

const EditTransactionModal = ({
  loadingTransaction,
  openDialog,
  handleClose,
  currentTransaction,
  handleUpdateTransactionStatus,
  handleAddId,
  handleEditId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const text = useRef('');
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openAddIdDialog, setOpenAddIdDialog] = useState(false);
  const [openEditIdDialog, setOpenEditIdDialog] = useState(false);
  const [openMissingStatusAlert, setOpenMissingStatusAlert] = useState(false);

  useEffect(() => {
    setStatus(currentTransaction.status);
    setUpdatedAt(currentTransaction.updated_at);
  }, [currentTransaction]);

  const handleCloseSaveDialog = () => {
    setOpenSaveDialog(false);
  };

  const handleCloseAddIdDialog = () => {
    setOpenAddIdDialog(false);
  };

  const handleCloseEditIdDialog = () => {
    setOpenEditIdDialog(false);
  };

  const onChangeId = (e) => {
    setId(text.current.value);
  };

  const onSubmit = () => {
    if (status) {
      setOpenSaveDialog(true);
    } else {
      setOpenMissingStatusAlert(true);
      setTimeout(() => {
        setOpenMissingStatusAlert(false);
      }, 1000);
    }
  };

  const onSave = () => {
    const body = {
      merchantTransactionId: currentTransaction.id,
      status: status,
    };
    handleUpdateTransactionStatus(body);
    setOpenSaveDialog(false);
    setOpenAddIdDialog(false);
    handleClose();
  };

  const onAddId = () => {
    const body = {
      dossierId: id,
      merchantTransactionId: currentTransaction.id,
    };
    handleAddId(body);
    handleCloseAddIdDialog();
  };

  const onEditId = () => {
    const body = {
      dossierId: id,
      merchantTransactionId: currentTransaction.id,
    };
    handleEditId(body);
    handleCloseEditIdDialog();
  };

  return (
    <Fragment>
      {/* Main dialog */}
      <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle className="m-2">
          <Grid container direction="row" justify="space-between">
            {'Editar transacción'}
            <TransactionStatus
              status={currentTransaction.loanStatus}
              className="right"
            />
          </Grid>
          <Typography variant="caption" className={classes.greyLightText}>
            Última modificación: {updatedAt}
          </Typography>
        </DialogTitle>
        <DialogContent className="m-2">
          {!loadingTransaction && currentTransaction.dossierId && (
            <Typography variant="body1" color="primary">
              {currentTransaction.dossierId}
              <IconButton
                onClick={() => {
                  setOpenEditIdDialog(true);
                }}>
                <Icon className={classes.editId}>edit</Icon>
              </IconButton>
            </Typography>
          )}
          {loadingTransaction && (
            <CircularProgress style={{ height: 24, width: 24 }} />
          )}
          {!loadingTransaction && !currentTransaction.dossierId && (
            <Box display="flex" justifyContent="flex-start">
              <Button
                variant="text"
                color="primary"
                style={{ fontSize: 13 }}
                onClick={() => {
                  setOpenAddIdDialog(true);
                }}
                startIcon={
                  <Avatar className="avatar-button">
                    <AddIcon />
                  </Avatar>
                }>
                Añadir nuevo ID
              </Button>
            </Box>
          )}
          <Typography variant="h5" className={classes.title}>
            {currentTransaction.id}
            <CopyToClipboard text={currentTransaction.id}>
              <IconButton>
                <Icon className={classes.contentCopy}>content_copy</Icon>
              </IconButton>
            </CopyToClipboard>
          </Typography>
          <Paper xs={12} elevation={0} className={classes.detailPaper}>
            <div className={classes.root}>
              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography
                        variant="caption"
                        className={classes.greyLightText}>
                        Principal
                      </Typography>
                      <br />
                      <Typography variant="caption" className={classes.text}>
                        {currencyFormat(currentTransaction.loan_principal)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="caption"
                        className={classes.greyLightText}>
                        Plazo
                      </Typography>
                      <br />
                      <Typography variant="caption" className={classes.text}>
                        {currentTransaction.loan_term}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="caption"
                        className={classes.greyLightText}>
                        Interés
                      </Typography>
                      <br />
                      <Typography variant="caption" className={classes.text}>
                        {formatPercent(currentTransaction.loan_annual_interest)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {smUp && (
                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Divider
                        orientation="vertical"
                        flexItem
                        className="mr-3"
                      />
                      <Grid item xs={5} className={classes.textCenter}>
                        <Icon className={classes.icons}>store</Icon>
                        <br />
                        <Typography variant="caption" className={classes.text}>
                          {truncateString(
                            currentTransaction.merchant_full_name,
                            17
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.textCenter}>
                        <Icon className={classes.icons}>arrow_right_alt</Icon>
                      </Grid>
                      <Grid item xs={5} className={classes.textCenter}>
                        <Icon className={classes.icons}>account_circle</Icon>
                        <br />
                        <Typography variant="caption" className={classes.text}>
                          {truncateString(
                            currentTransaction.borrower_full_name,
                            17
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </Paper>
          <FormControl className={classes.formControl}>
            <InputLabel id="status-select-label">
              Cambiar a un nuevo estado
            </InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={status || ''}
              onChange={(e) => {
                setStatus(e.target.value);
              }}>
              {COFIDIS_STATUS.map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Collapse in={openMissingStatusAlert}>
            <Typography variant="body1" color="error">
              Selecciona un estado, por favor.
            </Typography>
          </Collapse>
        </DialogContent>
        <DialogActions className="m-2">
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={onSubmit} color="primary" autoFocus>
              Guardar
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Guardar los cambios dialog */}
      <Dialog
        open={openSaveDialog}
        onClose={handleCloseSaveDialog}
        fullWidth
        maxWidth="xs">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`¿Deseas guardar `}
            {status}
            {` como nuevo estado ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveDialog} color="primary">
            No
          </Button>
          <Button onClick={onSave} color="primary" autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>

      {/* Añadir nuevo ID */}
      <Dialog
        open={openAddIdDialog}
        onClose={handleCloseAddIdDialog}
        fullWidth
        maxWidth="xs">
        <DialogTitle id="alert-dialog-add-dossier-title">
          Añadir nuevo ID
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Añade un nuevo ID para identificar esta transacción
          </DialogContentText>
          <TextField
            onChange={onChangeId}
            inputRef={text}
            autoFocus
            margin="dense"
            id="name"
            label="ID"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddIdDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={onAddId} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Editar ID */}
      <Dialog
        open={openEditIdDialog}
        onClose={handleCloseEditIdDialog}
        fullWidth
        maxWidth="xs">
        <DialogTitle id="alert-dialog-add-dossier-title">Editar ID</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Añadir nuevo ID para identificar esta transacción
            <br />
          </DialogContentText>
          <Typography color="primary" align="center">
            {currentTransaction.dossierId}
          </Typography>
          <TextField
            onChange={onChangeId}
            inputRef={text}
            autoFocus
            margin="dense"
            id="name"
            label="ID"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditIdDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={onEditId} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

EditTransactionModal.propTypes = {
  loadingTransaction: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTransaction: PropTypes.object.isRequired,
  handleUpdateTransactionStatus: PropTypes.func.isRequired,
  handleAddId: PropTypes.func.isRequired,
  handleEditId: PropTypes.func.isRequired,
};

export default EditTransactionModal;
